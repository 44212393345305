<template>
  <PanelSection :title="formLabels.labelSectionTitle" :hide-back-button="false">
    <div class="company-keys-form">
      <v-container>
        <CustomCard :card-v-options="{ width: '50%' }">
          <v-form novalidate @submit.prevent="onSubmit">
            <!-- code -->
            <v-text-field
              v-model="$v.formFields.code.$model"
              label="Código cliente"
              :error-messages="checkFieldErrors('code')"
              @input="touchField('code')"
              @blur="touchField('code')"
            />
            <!-- company Name -->
            <v-text-field
              v-model="$v.formFields.companyName.$model"
              label="Nombre establecimiento"
              :error-messages="checkFieldErrors('companyName')"
              @input="touchField('companyName')"
              @blur="touchField('companyName')"
            />
            <!-- area -->
            <v-select
              v-model="$v.formFields.area.$model"
              :items="getUserAreas"
              label="Área"
              :error-messages="checkFieldErrors('area')"
              @change="touchField('area')"
            ></v-select>
            <!-- management -->
            <v-text-field
              v-model="$v.formFields.management.$model"
              label="Nº de Gerencia"
              :error-messages="checkFieldErrors('management')"
              @input="touchField('management')"
              @blur="touchField('management')"
            />
            <!-- managementName -->
            <v-text-field
              v-model="$v.formFields.managementName.$model"
              label="Nombre Gerencia"
              :error-messages="checkFieldErrors('managementName')"
              @input="touchField('managementName')"
              @blur="touchField('managementName')"
            />
            <!-- promoter -->
            <v-text-field
              v-model="$v.formFields.promoter.$model"
              label="Promotor"
              :error-messages="checkFieldErrors('promoter')"
              @input="touchField('promoter')"
              @blur="touchField('promoter')"
            />
            <!-- route -->
            <v-text-field
              v-model="$v.formFields.route.$model"
              label="Ruta"
              :error-messages="checkFieldErrors('route')"
              @input="touchField('route')"
              @blur="touchField('route')"
            />
            <!-- action buttons -->
            <v-card-actions>
              <FormButtons
                :accept-button-text="formLabels.labelAcceptButton"
                :accept-button-loading="formProcessing"
                :cancel-button-text="formLabels.labelCancelButton"
                @onClickCancelButton="handleClickCancelButton"
              />
            </v-card-actions>
          </v-form>
        </CustomCard>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Constants
import { ROLES } from '@/constants'
// Components
import CustomCard from '@/components/ui/CustomCard'
import PanelSection from '@/components/ui/PanelSection'
import FormButtons from '@/components/ui/FormButtons'
// Vuex
import { mapGetters } from 'vuex'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'
// Services
import {
  getCompanyKeysById,
  createCompanyKeys,
  updateCompanyKeys,
  delCompanyKeysById
} from '@/services/companyKeys'
// Utils
import { get } from 'lodash'

export default {
  name: 'CompanyKeysForm',
  components: {
    CustomCard,
    PanelSection,
    FormButtons
  },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Data item
      id: this.$route.params.id || null,
      // Form
      formFields: {
        area: null,
        code: null,
        companyName: null,
        management: null,
        managementName: null,
        promoter: null,
        route: null
      },
      formFieldsValidations: {
        area: {
          required: 'No puede quedar vacío',
          integer: 'Debe ser un número entero'
        },
        code: {
          required: 'No puede quedar vacío'
        },
        companyName: {
          required: 'No puede quedar vacío'
        },
        management: {
          required: 'No puede quedar vacío'
        },
        managementName: {
          required: 'No puede quedar vacío'
        },
        promoter: {
          required: 'No puede quedar vacío'
        },
        route: {
          required: 'No puede quedar vacío'
        }
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * Get the areas the user has
     */
    getUserAreas() {
      return this.userData.role === ROLES.admin.value
        ? ['1', '2', '3', '4', '5', '6']
        : get(this.userData, 'meta.areas', [])
    },
    /**
     * Get the labels (texts) to show in the formulary
     */
    formLabels() {
      return {
        labelAcceptButton: this.id ? 'Guardar' : 'Crear',
        labelCancelButton: 'Cancelar',
        labelSectionTitle: this.id ? 'Editar clave' : 'Crear clave'
      }
    }
  },
  async mounted() {
    await this.setItemData(this.id)
  },
  methods: {
    /**
     * When click on "cancel" button
     */
    handleClickCancelButton() {
      this.redirectToList()
    },
    /**
     * Redirect to list
     */
    redirectToList() {
      this.routerPushTo({
        path: '/company-keys'
      })
    },
    /**
     * Set the item data
     */
    async setItemData(id) {
      if (id) {
        const itemData = await getCompanyKeysById(id)

        if (!itemData) {
          this.redirectToList()
        } else {
          this.formFields = {
            code: itemData.code || id,
            area: itemData.area || null,
            companyName: itemData.companyName || null,
            management: itemData.management || null,
            managementName: itemData.managementName || null,
            promoter: itemData.promoter || null,
            route: itemData.route || null
          }
        }
      }
    },
    /**
     * Is triggered after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        if (this.id !== this.formFields.code) {
          // Remove old document (register)
          delCompanyKeysById(this.id)
          // Test if exists othe code with the same ID
          const companyKeysData = await getCompanyKeysById(this.formFields.code)

          if (!companyKeysData) {
            // Create new register
            createCompanyKeys(this.formFields, this.formFields.code)
          } else {
            // Redirect to List
            this.redirectToList()
            // Throw error
            throw new Error('Ya existe otro código de cliente con ese valor')
          }
        } else {
          // Update register
          updateCompanyKeys({ id: this.id, ...this.formFields })
        }
        // show info
        this.modifyAppAlert({
          text: 'Los cambios se guardaron correctamente',
          visible: true
        })
        // Redirect to list
        this.redirectToList()
      } catch (error) {
        // show error
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      area: {
        required,
        integer
      },
      code: {
        required
      },
      companyName: {
        required
      },
      management: {
        required
      },
      managementName: {
        required
      },
      promoter: {
        required
      },
      route: {
        required
      }
    }
  }
}
</script>
